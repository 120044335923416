<template>
   <table class="comparateur2"> 
        <thead>
            <tr >
                <th width="33%" class="first">{{$t('Treatment line')}}</th>
                <th width="33%">{{$t('Specificity')}}</th>
                <th width="33%" class="last">{{$t('Administration')}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><span v-html="data.treatment_line ? treatment_line(data.treatment_line) : '-'"></span></td>
                <td>{{check_empty(data.specificity)}}</td>
                <td>{{check_empty(data.administration)}}</td>
            </tr>
            
        </tbody>
    </table>
</template>

<script>
import { check_empty, treatment_line } from '../../utils'
export default {
    name : "TreatmentLine",
    computed : {
        data() {
            return this.$store.getters['detail/data']
        }
    },
    methods : {
        check_empty, treatment_line
    },
}
</script>

<style>

</style>