<template>
    <div class="content-onglets table">
        <table class="comparateur2 border-top-gray">
            <template v-for="(value, key) in key_documents(data)">
                <template v-if="value['section']">
                    <tr :key="'key_document_section_'+key">
                        <td colspan="3"><strong>{{value['section']}}</strong></td>
                    </tr>
                    <tr :key="'key_document_'+key+'_'+key2" v-for="(value2, key2) in value['data']">
                        <td width="20%"> {{DDMMMYY(value2['date'])}} </td>
                        <td width="70%"> <span v-html="value2['title'] ? $t(value2['title']) : ''"></span> </td>
                        <td width="10%"> <KeyDocumentIcon :value="value2" :list_document_exist="list_document_exist"/> </td>
                    </tr>
                </template>
                <template v-else>
                    <tr :key="'key_document_'+key">
                        <td width="20%"> {{DDMMMYY(value['date'])}} </td>
                        <td width="70%"> <span v-html="value['title'] ? $t(value['title']) : ''"></span></td>
                        <td width="10%"> <KeyDocumentIcon :value="value" :list_document_exist="list_document_exist"/> </td>
                    </tr>
                </template>
                
            </template>
        </table>              
    </div>   
</template>

<script>
import { DDMMMYY, key_documents } from '../../utils'
import KeyDocumentIcon from './KeyDocumentIcon.vue'
export default {
    name : "KeyDocument",
    components : {
        KeyDocumentIcon
    },
    props : {
        data : {
            style : Array
        },
        list_document_exist : {
            style : Array
        }
    },
    computed : {
    },
    methods : {
        DDMMMYY,
        key_documents
    },
}
</script>

<style>

</style>