<template>
    <div>        
            <!-- Section Title End -->           
        <div  class="content-onglets" v-if="show">                                       
            <table class="comparateur2">                  
                <thead>
                    <tr>
                        <th width="10%" class="first">&nbsp;</th>
                        <th width="20%">Drug name</th>
                        <th width="30%">Packaging</th>
                        <th width="10%">Units</th>
                    </tr>
                </thead>
                <tbody>                                               
                    <tr v-for="(item, key) in data.data" :key="'link'+key+'tr'">
                        <td>
                            <router-link :to="'/detail/'+item.prix_id+'/'+link_prix" target="_blank" rel="noopener">
                                <em class="icons icon-plus-m"></em>
                            </router-link>
                        </td>            
                        <td>{{item.name ? item.name : '-'}}</td>            
                        <td>{{item.package ? item.package : '-'}}</td>            
                        <td>{{item.units ? item.units : '-'}}</td>        
                    </tr>                       
                </tbody>               
            </table>                   
        </div>             
    </div>
</template>

<script>
export default {
    name : "LinkPrix",
    props : {
        data : {
            style : Object
        },
        link_prix : {
            style : String
        },
        show : {
            style : Boolean
        }    
    },
}
</script>

<style>

</style>