<template>
    <div class="box-white-fiche" :id="'link_agency_'+link_agency" v-if="show_block.includes('link_agency_'+link_agency)" >
        <!-- Section Title Start -->
        
        <div class="box-icon-openbar">
            <div class="box-click-open" @click="show = !show">
                <h3>
                    <img :alt="data.agency_model" :src="require('../../assets/images/flag/new/'+data.icon_flags+'.svg')" width="20" height="20" style="margin-right: 5px;" />
                    &nbsp;&nbsp;&nbsp; {{!link_agency.startsWith('Prix') ? data.title : 'Drugs'}}
                </h3>
                <em :class="show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
            </div>                 
            <div class="clearfix"></div>
        </div>

        <!-- Section Title End -->            
        <div  class="content-onglets" v-if="!link_agency.startsWith('Prix') && show"  >                                       
            <table class="comparateur2">
                <thead>
                    <tr>
                        <th width="50" class="first">&nbsp;</th>
                        <template v-if="data.agency_type == 'HTA/Reimbursement' || data.agency_type == 'HTA'">
                            <th width="30%">{{$t('linkAgency.HTA date')}}</th>
                            <th width="30%">{{$t('linkAgency.HTA')}}</th>
                        </template>
                        <template v-else-if="data.agency_type == 'Reimbursement'">
                            <th width="30%">{{$t('linkAgency.Reimbursement date')}}</th>
                            <th width="30%">{{$t('linkAgency.Reimbursement')}}</th>
                        </template>
                        <template v-else>
                            <th width="30%">{{$t('linkAgency.Decision date')}}</th>
                            <th width="30%">{{$t('linkAgency.Decision')}}</th>
                        </template>      
                        <th >{{$t('linkAgency.Classification')}}</th>         
                    </tr>
                </thead>
                <tbody>                                               
                    <tr v-for="(item, key) in data.data" :key="'link'+link_agency+key+'tr'">
                        <td>              
                            <router-link :to="'/detail/'+item.drug_id+'/'+link_detail_url(item.agency_model)" target="_blank" rel="noopener">
                                <em class="icons icon-plus-m"></em>
                            </router-link>
                        </td>
                        <template v-if="data.agency_type == 'HTA/Reimbursement' || data.agency_type == 'HTA'">
                            <td >{{item['hta_process_date'] ? DDMMMYY(item['hta_process_date']) : '-' }} </td>
                            <td >{{item['hta_decision']? $t('decision.'+item['hta_decision']).replace('decision.', '') : '-'}} </td>
                        </template>
                        <template v-else-if="data.agency_type == 'Reimbursement'">
                            <td > {{item['rembursement_process_date'] ?  DDMMMYY(item['rembursement_process_date']) : '-'}}   </td>
                            <td > {{item['reimbursement_decision'] ? $t('decision.'+item['reimbursement_decision']).replace('decision.', '') : '-'}}   </td>
                        </template>
                        <template v-else>
                            <td > {{ item['decision_date_num']? DDMMMYY(item['decision_date_num']) : '-' }}</td>
                            <td > {{ item['decision'] ? $t('decision.'+item['decision']).replace('decision.', '') : '-' }}   </td>
                        </template>
                        <td>  
                            <span v-if="$i18n.locale == 'fr'" v-html="item['classification_fr'] ? array(item['classification_fr']): '-'"></span>
                            <span v-else v-html="item['classification_en']? array(item['classification_en']) : '-'"></span>   
                        </td>                               
                    </tr>            
                </tbody>
            </table>                   
        </div>  
        <LinkPrix v-else :link_prix="link_agency" :data="data" :show="show"/>           
    </div>
</template>

<script>
import { link_detail_url, array, DDMMMYY } from '../../utils'
import LinkPrix from './LinkPrix.vue'
export default {
    name : "LinkAgency",
    data() {
        return {
            show : true
        }
    },
    props : {
        data : {
            style : Object
        },
        link_agency : {
            style : String
        }      
        
    },
    components : {
        LinkPrix
    },
    computed : {
        agency(){
            return this.$store.getters['detail/agency']
        },
        show_block() {
            return this.$store.getters['detail/show_block']
        }
    },
    methods:{  
        link_detail_url,
        array,
        DDMMMYY       
    },
}
</script>

<style>

</style>